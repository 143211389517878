const getEditorSuggestions = (monaco) => [
    // Built-in Functions
    {
        label: 'print',
        kind: monaco.languages.CompletionItemKind.Function,
        insertText: 'print(${1})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Print objects to the text stream',
        documentation: 'Print objects to the text stream file, separated by sep and followed by end.'
      },
      {
        label: 'input',
        kind: monaco.languages.CompletionItemKind.Function,
        insertText: 'input(${1})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Read a string from standard input',
        documentation: 'Read a string from standard input.'
      },
      {
        label: 'len',
        kind: monaco.languages.CompletionItemKind.Function,
        insertText: 'len(${1})',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Return the length of an object',
        documentation: 'Return the length (the number of items) of an object.'
      },
      {
        label: 'if',
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: 'if ${1:condition}:\n\t${2}',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'If statement',
        documentation: 'Execute a block of code if a condition is true.'
      },
      {
        label: 'elif',
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: 'elif ${1:condition}:\n\t${2}',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Else If statement',
        documentation: 'Execute a block of code if a condition is true.'
      },
      {
        label: 'else',
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: 'else:\n\t${1}',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Else statement',
        documentation: 'Execute a block of code if none of the conditions are true.'
      },
      {
        label: 'for',
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: 'for ${1:item} in ${2:items}:\n\t${3}',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'For loop',
        documentation: 'Iterate over a sequence of items.'
      },
      {
        label: 'while',
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: 'while ${1:condition}:\n\t${2}',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'While loop',
        documentation: 'Execute statements repeatedly as long as a condition is true.'
      },
      {
        label: 'def',
        kind: monaco.languages.CompletionItemKind.Snippet,
        insertText: 'def ${1:function_name}(${2:parameters}):\n\t${3:pass}',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Function definition',
        documentation: 'Define a new function.'
      },
      {
        label: 'class',
        kind: monaco.languages.CompletionItemKind.Snippet,
        insertText: 'class ${1:ClassName}:\n\tdef __init__(self):\n\t\t${2:pass}',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Class definition',
        documentation: 'Define a new class.'
      },
      {
        label: 'import',
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: 'import ${1:module}',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'Import statement',
        documentation: 'Import a module.'
      }
];

export default getEditorSuggestions;