import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import bowser from 'bowser';

import VM, { on } from 'scratch-vm';
import generateAndUploadCode from './ArduinoUploader.jsx';

import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import CommunityButton from './community-button.jsx';
import ShareButton from './share-button.jsx';
import { ComingSoonTooltip } from '../coming-soon/coming-soon.jsx';
import Divider from '../divider/divider.jsx';
import SaveStatus from './save-status.jsx';
import ProjectWatcher from '../../containers/project-watcher.jsx';
import MenuBarMenu from './menu-bar-menu.jsx';
import { MenuItem, MenuSection } from '../menu/menu.jsx';
import ProjectTitleInput from './project-title-input.jsx';
import AuthorInfo from './author-info.jsx';
import AccountNav from '../../containers/account-nav.jsx';
import SB3Downloader from '../../containers/sb3-downloader.jsx';
import DeletionRestorer from '../../containers/deletion-restorer.jsx';
import TurboMode from '../../containers/turbo-mode.jsx';
import MenuBarHOC from '../../containers/menu-bar-hoc.jsx';
import SettingsMenu from './settings-menu.jsx';
import BoardSelectionModal from './boardSelectionModal.jsx';
import React, { useEffect, useState } from 'react';
import ConnectModal from './connect-modal.jsx';
import UnityGame from '../gui/game.jsx'
import Filesharing from './FileSharingModal.jsx';
import { FileCodeIcon, Edit3Icon, GraduationCapIcon, ZapIcon, CpuIcon, CableIcon, Boxes } from 'lucide-react'

import { openTipsLibrary } from '../../reducers/modals';
import { setPlayer } from '../../reducers/mode';
import {
    isTimeTravel220022BC,
    isTimeTravel1920,
    isTimeTravel1990,
    isTimeTravel2020,
    isTimeTravelNow,
    setTimeTravel
} from '../../reducers/time-travel';
import {
    autoUpdateProject,
    getIsUpdating,
    getIsShowingProject,
    manualUpdateProject,
    requestNewProject,
    remixProject,
    saveProjectAsCopy
} from '../../reducers/project-state';
import {
    openAboutMenu,
    closeAboutMenu,
    aboutMenuOpen,
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen,
    openModeMenu,
    closeModeMenu,
    modeMenuOpen,
    settingsMenuOpen,
    openSettingsMenu,
    closeSettingsMenu
} from '../../reducers/menus';

import collectMetadata from '../../lib/collect-metadata';

import styles from './menu-bar.css';

import helpIcon from '../../lib/assets/icon--tutorials.svg';
import mystuffIcon from '../../lib/assets/icon--tutorials.svg';//'./icon--mystuff.png';
import profileIcon from '../../lib/assets/icon--tutorials.svg';//'./icon--profile.png';
import remixIcon from './icon--remix.svg';
import dropdownCaret from './dropdown-caret.svg';
import aboutIcon from './icon--about.svg';

import scratchLogo from './stemverse-logo.png';
// import scratchLogo from './new-logo.png';

import ninetiesLogo from './nineties_logo.svg';
import catLogo from './cat_logo.svg';
import prehistoricLogo from './prehistoric-logo.svg';
import oldtimeyLogo from './oldtimey-logo.svg';

import sharedMessages from '../../lib/shared-messages';
import axios from 'axios';
import ProjectService from '../../lib/project-service';


const ariaMessages = defineMessages({
    tutorials: {
        id: 'gui.menuBar.tutorialsLibrary',
        defaultMessage: 'Tutorials',
        description: 'accessibility text for the tutorials button'
    },
    burnCode: {
        id: 'gui.menuBar.burnCodeToArduino',
        defaultMessage: 'Burn Code',
        description: 'Accessibility text for the burn code button'
    }

});

let selectedBoard = null;

const MenuBarItemTooltip = ({
    children,
    className,
    enable,
    id,
    place = 'bottom'
}) => {
    if (enable) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
    return (
        <ComingSoonTooltip
            className={classNames(styles.comingSoon, className)}
            place={place}
            tooltipClassName={styles.comingSoonTooltip}
            tooltipId={id}
        >
            {children}
        </ComingSoonTooltip>
    );
};


MenuBarItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enable: PropTypes.bool,
    id: PropTypes.string,
    place: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

const MenuItemTooltip = ({ id, isRtl, children, className }) => (
    <ComingSoonTooltip
        className={classNames(styles.comingSoon, className)}
        isRtl={isRtl}
        place={isRtl ? 'left' : 'right'}
        tooltipClassName={styles.comingSoonTooltip}
        tooltipId={id}
    >
        {children}
    </ComingSoonTooltip>
);

MenuItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    isRtl: PropTypes.bool
};

const AboutButton = props => (
    <Button
        className={classNames(styles.menuBarItem, styles.hoverable)}
        iconClassName={styles.aboutIcon}
        iconSrc={aboutIcon}
        onClick={props.onClick}
    />
);

AboutButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

class MenuBar extends React.Component {
    constructor(props) {
        super(props);
        // this.handleJoinStemClick = this.handleJoinStemClick.bind(this);
        // this.handleCloseForm = this.handleCloseForm.bind(this);
        localStorage.setItem('selectedBoard', null);

        bindAll(this, [
            'handleClickNew',
            'handleClickRemix',
            'handleClickSave',
            'handleClickSaveAsCopy',
            'handleClickSeeCommunity',
            'handleClickShare',
            'handleSetMode',
            'handleKeyPress',
            'handleRestoreOption',
            'getSaveToComputerHandler',
            'restoreOptionMessage',
            'handleFileSharing',
            'handleOpenFileLink',
            'handleOpenFileQR',
            'openBoardModal',
            'closeBoardModal',
            'handleUndo',
            'handleRedo',
            'openConnectModal',
            'closeConnectModal',
            "openfilemodal",
            'closefilemodal',
            'handleBoardSelect',
            'handleJoinStemClick',
            'handleCloseForm',
            'handleBurnSelect',
            'openbuildRoboModal',
            'closebuildRoboModal',
            'handleSaveProject',
            'handleNewProject',
            'handleProjectChanged'
            // 'handleSettings'
        ]);

        this.state = {
            isConnectModalOpen: false,
            isbuildRoboModalOpen: false,
            isFileopen: false,
            isModalOpen: false,
            showRegistrationForm: false,
            loginMenuOpen: false,
            hasUnsavedChanges: false,
            isSaving: false
        };

    }

    handleBoardSelect(board) {
        console.log('Selected board:', board);
        selectedBoard = board;
        // Add your logic here to handle the board selection
    }

    render() {
        return (
            <div>
                <BoardSelectionModal
                    onBoardSelect={this.handleBoardSelect}
                    onClose={() => console.log('Modal closed')}
                />
            </div>
        );
    }

    handleJoinStemClick() {
        this.setState({ showRegistrationForm: true });
    }

    handleCloseForm() {
        this.setState({ showRegistrationForm: false });
    }

    openConnectModal() {
        this.setState({ isConnectModalOpen: true });
    }

    closeConnectModal() {
        this.setState({ isConnectModalOpen: false });
    }

    openbuildRoboModal() {
        this.setState({ isbuildRoboModalOpen: true });
    }

    closebuildRoboModal() {
        this.setState({ isbuildRoboModalOpen: false });
    }
    render() {
        return (
            <div>
                <UnityGame
                    onClose={() => console.log('Modal closed')}
                />
            </div>
        );
    }

    openfilemodal() {
        console.log('enter')
        this.setState({ isfileopen: true });
    }
    closefilemodal() {
        this.setState({ isfileopen: false });
    }

    renderLogin = ({ onClose }) => {
        return (
            <div>
                {/* Login form content */}
                <form>
                    <h1 style={{ color: "black" }}>User Login</h1>
                    <input type="text" placeholder="Username" className="form-control" />
                    <input type="password" placeholder="Password" />
                    <button type="submit">Sign in</button>
                    <button onClick={onClose}>Close</button>
                </form>
            </div>
        );
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
        localStorage.setItem('isConnected', 'false');
        this.props.vm.on('PROJECT_CHANGED', this.handleProjectChanged);
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
        this.props.vm.removeListener('PROJECT_CHANGED', this.handleProjectChanged);
    }
    openBoardModal() {
        this.setState({ isModalOpen: true });
    }

    closeBoardModal() {
        this.setState({ isModalOpen: false });
    }
    handleClickNew() {
        // if the project is dirty, and user owns the project, we will autosave.
        // but if they are not logged in and can't save, user should consider
        // downloading or logging in first.
        // Note that if user is logged in and editing someone else's project,
        // they'll lose their work.
        const readyToReplaceProject = this.props.confirmReadyToReplaceProject(
            this.props.intl.formatMessage(sharedMessages.replaceProjectWarning)
        );
        this.props.onRequestCloseFile();
        if (readyToReplaceProject) {
            this.props.onClickNew(this.props.canSave && this.props.canCreateNew);
        }
        this.props.onRequestCloseFile();
    }
    handleClickRemix() {
        this.props.onClickRemix();
        this.props.onRequestCloseFile();
    }
    handleClickSave() {
        this.props.onClickSave();
        this.props.onRequestCloseFile();
    }
    handleClickSaveAsCopy() {
        this.props.onClickSaveAsCopy();
        this.props.onRequestCloseFile();
    }
    handleFileSharing() {
        if (this.props.onclickfile) {
            this.props.onclickfile();
        } else {
            console.error('onclickfile function is not defined');
        }
    }
    handleClickSeeCommunity(waitForUpdate) {
        if (this.props.shouldSaveBeforeTransition()) {
            this.props.autoUpdateProject(); // save before transitioning to project page
            waitForUpdate(true); // queue the transition to project page
        } else {
            waitForUpdate(false); // immediately transition to project page
        }
    }
    handleClickShare(waitForUpdate) {
        if (!this.props.isShared) {
            if (this.props.canShare) { // save before transitioning to project page
                this.props.onShare();
            }
            if (this.props.canSave) { // save before transitioning to project page
                this.props.autoUpdateProject();
                waitForUpdate(true); // queue the transition to project page
            } else {
                waitForUpdate(false); // immediately transition to project page
            }
        }
    }



    async handleBurnSelect() {
        try {
            let selectedBoardObject = localStorage.getItem('selectedBoard');
            selectedBoardObject = JSON.parse(selectedBoardObject);
            if (selectedBoardObject.name === 'ESP32') {
                // Request permission to access the serial port
                const port = await navigator.serial.requestPort();
                // await port.open({ baudRate: 115200 });
                // await port.open({ baudRate: 921600 });
                console.log('Port selected:', port);

                // Pass the port to generateAndUploadCode
                await generateAndUploadCode(port);
                // alert('This board is not supported yet.');
            } else if (selectedBoardObject.name === 'Arduino Uno') {
                await generateAndUploadCode();
            } else {
                alert('This board is not supported yet.');
            }
        } catch (error) {
            console.error('Failed to access the serial port or burn code:', error.message);
            alert('Failed to proceed. Please ensure a board is selected and try again.');
        }
    }
    handleOpenFileLink() {
        alert("Open file from link")
    }
    handleOpenFileQR() {
        alert("Open file from QR")
    }
    handleUndo() {
        alert("Undo");
    }
    handleRedo() {
        alert("Redo");
    }
    handleSetMode(mode) {
        return () => {
            // Turn on/off filters for modes.
            if (mode === '1920') {
                document.documentElement.style.filter = 'brightness(.9)contrast(.8)sepia(1.0)';
                document.documentElement.style.height = '100%';
            } else if (mode === '1990') {
                document.documentElement.style.filter = 'hue-rotate(40deg)';
                document.documentElement.style.height = '100%';
            } else {
                document.documentElement.style.filter = '';
                document.documentElement.style.height = '';
            }

            // Change logo for modes
            if (mode === '1990') {
                document.getElementById('logo_img').src = ninetiesLogo;
            } else if (mode === '2020') {
                document.getElementById('logo_img').src = catLogo;
            } else if (mode === '1920') {
                document.getElementById('logo_img').src = oldtimeyLogo;
            } else if (mode === '220022BC') {
                document.getElementById('logo_img').src = prehistoricLogo;
            } else {
                document.getElementById('logo_img').src = this.props.logo;
            }

            this.props.onSetTimeTravelMode(mode);
        };
    }
    handleRestoreOption(restoreFun) {
        return () => {
            restoreFun();
            this.props.onRequestCloseEdit();
        };
    }
    handleKeyPress(event) {
        const modifier = bowser.mac ? event.metaKey : event.ctrlKey;
        if (modifier && event.key === 's') {
            this.props.onClickSave();
            event.preventDefault();
        }
    }
    getSaveToComputerHandler(downloadProjectCallback) {
        return () => {
            this.props.onRequestCloseFile();
            downloadProjectCallback();
            if (this.props.onProjectTelemetryEvent) {
                const metadata = collectMetadata(this.props.vm, this.props.projectTitle, this.props.locale);
                this.props.onProjectTelemetryEvent('projectDidSave', metadata);
            }
        };
    }
    restoreOptionMessage(deletedItem) {
        switch (deletedItem) {
            case 'Sprite':
                return (<FormattedMessage
                    defaultMessage="Restore Sprite"
                    description="Menu bar item for restoring the last deleted sprite."
                    id="gui.menuBar.restoreSprite"
                />);
            case 'Sound':
                return (<FormattedMessage
                    defaultMessage="Restore Sound"
                    description="Menu bar item for restoring the last deleted sound."
                    id="gui.menuBar.restoreSound"
                />);
            case 'Costume':
                return (<FormattedMessage
                    defaultMessage="Restore Costume"
                    description="Menu bar item for restoring the last deleted costume."
                    id="gui.menuBar.restoreCostume"
                />);
            default: {
                return (<FormattedMessage
                    defaultMessage="Restore"
                    description="Menu bar item for restoring the last deleted item in its disabled state." /* eslint-disable-line max-len */
                    id="gui.menuBar.restore"
                />);
            }
        }
    }
    buildAboutMenu(onClickAbout) {
        if (!onClickAbout) {
            // hide the button
            return null;
        }
        if (typeof onClickAbout === 'function') {
            // make a button which calls a function
            return <AboutButton onClick={onClickAbout} />;
        }
        // assume it's an array of objects
        // each item must have a 'title' FormattedMessage and a 'handleClick' function
        // generate a menu with items for each object in the array
        return (
            <div
                className={classNames(styles.menuBarItem, styles.hoverable, {
                    [styles.active]: this.props.aboutMenuOpen
                })}
                onMouseUp={this.props.onRequestOpenAbout}
            >
                <img
                    className={styles.aboutIcon}
                    src={aboutIcon}
                />
                <MenuBarMenu
                    className={classNames(styles.menuBarMenu)}
                    open={this.props.aboutMenuOpen}
                    place={this.props.isRtl ? 'right' : 'left'}
                    onRequestClose={this.props.onRequestCloseAbout}
                >
                    {
                        onClickAbout.map(itemProps => (
                            <MenuItem
                                key={itemProps.title}
                                isRtl={this.props.isRtl}
                                onClick={this.wrapAboutMenuCallback(itemProps.onClick)}
                            >
                                {itemProps.title}
                            </MenuItem>
                        ))
                    }
                </MenuBarMenu>
            </div>
        );
    }
    wrapAboutMenuCallback(callback) {
        return () => {
            callback();
            this.props.onRequestCloseAbout();
        };
    }
    handleProjectChanged() {
        this.setState({ hasUnsavedChanges: true });
    }

    async handleSaveProject() {
        const { vm, projectTitle } = this.props;
        this.setState({ isSaving: true });

        try {
            const projectData = await vm.saveProjectSb3();
            const currentProjectId = localStorage.getItem('currentProjectId');

            if (currentProjectId) {
                await ProjectService.updateProject(currentProjectId, projectData, projectTitle);
            } else {
                const response = await ProjectService.saveProject(projectData, projectTitle, true);
                localStorage.setItem('currentProjectId', response.data.projectId);
            }

            this.setState({ hasUnsavedChanges: false });
        } catch (error) {
            console.error('Failed to save project:', error);
        } finally {
            this.setState({ isSaving: false });
        }
    }

    handleNewProject() {
        if (this.state.hasUnsavedChanges) {
            const shouldProceed = window.confirm('You have unsaved changes. Do you want to create a new project anyway?');
            if (!shouldProceed) return;
        }

        this.props.vm.loadProject(this.props.vm.emptyProject());
        localStorage.removeItem('currentProjectId');
        this.setState({ hasUnsavedChanges: false });
    }

    render() {
        console.log('Rendering component, loginMenuOpen:', this.props.loginMenuOpen);
        const saveNowMessage = (
            <FormattedMessage
                defaultMessage="Save now"       /* here they are creating new gui componant for the file*/
                description="Menu bar item for saving now"
                id="gui.menuBar.saveNow"
            />
        );
        const createCopyMessage = (
            <FormattedMessage
                defaultMessage="Save as a copy"
                description="Menu bar item for saving as a copy"
                id="gui.menuBar.saveAsCopy"
            />
        );
        const remixMessage = (
            <FormattedMessage
                defaultMessage="Remix"
                description="Menu bar item for remixing"
                id="gui.menuBar.remix"
            />
        );
        const newProjectMessage = (
            <FormattedMessage
                defaultMessage="New"
                description="Menu bar item for creating a new project"
                id="gui.menuBar.new"
            />
        );
        const remixButton = (
            <Button
                className={classNames(
                    styles.menuBarButton,
                    styles.remixButton
                )}
                iconClassName={styles.remixButtonIcon}
                iconSrc={remixIcon}
                onClick={this.handleClickRemix}
            >
                {remixMessage}
            </Button>
        );
        // Show the About button only if we have a handler for it (like in the desktop app) 
        const aboutButton = this.buildAboutMenu(this.props.onClickAbout);
        return (
            <>
                <Box
                    className={classNames(
                        this.props.className,
                        styles.menuBar,
                        // 'bg-gradient-to-r from-blue-900 via-purple-800 to-blue-900'
                        // 'bg-blue-900'
                        'bg-gradient-to-r from-cyan-600 via-blue-900 to-cyan-600'
                    )}
                >
                    <div className={styles.mainMenu}>

                        <div className={styles.fileGroup}>
                            <div className={classNames(styles.menuBarItem)}>
                                <img
                                    id="logo_img"
                                    alt="Scratch"
                                    className={classNames(styles.scratchLogo, {
                                        [styles.clickable]: typeof this.props.onClickLogo !== 'undefined'
                                    })}
                                    draggable={false}
                                    src={this.props.logo}
                                // onClick={this.props.onClickLogo}
                                />
                            </div>
                            {(this.props.canChangeTheme || this.props.canChangeLanguage || this.props.audioVideoSetting) && (<SettingsMenu
                                canChangeLanguage={this.props.canChangeLanguage}
                                canChangeTheme={this.props.canChangeTheme}
                                audioVideoSetting={this.props.audioVideoSetting}
                                isRtl={this.props.isRtl}
                                onRequestClose={this.props.onRequestCloseSettings}
                                onRequestOpen={this.props.onClickSettings}
                                settingsMenuOpen={this.props.settingsMenuOpen}
                            />)}
                            {(this.props.canManageFiles) && (
                                <div
                                    className={classNames(styles.menuBarItem, styles.hoverable, {
                                        [styles.active]: this.props.fileMenuOpen
                                    })}
                                    onMouseUp={this.props.onClickFile}
                                >
                                    <FileCodeIcon size={19} />
                                    <span className={classNames(styles.collapsibleLabel)}>
                                        {/* File */}
                                        <FormattedMessage
                                            defaultMessage="MYFile"
                                            description="Text for file dropdown menu"
                                            id="gui.menuBar.file"
                                        />
                                    </span>
                                    <img src={dropdownCaret} />
                                    <MenuBarMenu
                                        className={classNames(styles.menuBarMenu, "bg-blue-800")}
                                        open={this.props.fileMenuOpen}
                                        place={this.props.isRtl ? 'left' : 'right'}
                                        onRequestClose={this.props.onRequestCloseFile}
                                    >
                                        <MenuSection className="bg-blue-800">
                                            <MenuItem
                                                isRtl={this.props.isRtl}
                                                onClick={this.handleClickNew}
                                            >
                                                {newProjectMessage}
                                            </MenuItem>
                                        </MenuSection>
                                        {(this.props.canSave || this.props.canCreateCopy || this.props.canRemix) && (
                                            <MenuSection>
                                                {this.props.canSave && (
                                                    <MenuItem onClick={this.handleClickSave}>
                                                        {saveNowMessage}
                                                    </MenuItem>
                                                )}
                                                {this.props.canCreateCopy && (
                                                    <MenuItem onClick={this.handleClickSaveAsCopy}>
                                                        {createCopyMessage}
                                                    </MenuItem>
                                                )}

                                                {this.props.canRemix && (
                                                    <MenuItem onClick={this.handleClickRemix}>
                                                        {remixMessage}
                                                    </MenuItem>
                                                )}
                                            </MenuSection>
                                        )}
                                        <MenuSection>
                                            <MenuItem
                                                onClick={this.props.onStartSelectingFileUpload}
                                            >
                                                {this.props.intl.formatMessage(sharedMessages.loadFromComputerTitle)}
                                            </MenuItem>
                                            <SB3Downloader>{(className, downloadProjectCallback) => (
                                                <MenuItem
                                                    className={className}
                                                    onClick={this.getSaveToComputerHandler(downloadProjectCallback)}
                                                >
                                                    <FormattedMessage
                                                        defaultMessage="Save to your computer"
                                                        description="Menu bar item for downloading a project to your computer" // eslint-disable-line max-len
                                                        id="gui.menuBar.downloadToComputer"
                                                    />
                                                </MenuItem>
                                            )}</SB3Downloader>
                                            <MenuItem
                                                onClick={this.openfilemodal}
                                            >
                                                <FormattedMessage
                                                    defaultMessage="File Sharing"
                                                    description="Menu bar item for sharing a project "
                                                    id="gui.menubar.fileSharing"
                                                />
                                            </MenuItem>
                                            <div>
                                                <MenuItem
                                                    onClick={this.handleOpenFileLink}
                                                >
                                                    <FormattedMessage
                                                        defaultMessage="Open file from link"
                                                        description="Menu bar item for opening file from a link"
                                                        id="gui.menubar.openFileLink"
                                                    />
                                                </MenuItem>
                                            </div>
                                            <MenuItem
                                                onClick={this.handleOpenFileQR}
                                            >
                                                <FormattedMessage
                                                    defaultMessage="Open file from QR code"
                                                    description="Menu bar item for opening file from QR code"
                                                    id="gui.menubar.openFileQr"
                                                />
                                            </MenuItem>
                                            {/* </MenuSection>
                                    <MenuSection>
                                         */}
                                        </MenuSection>
                                    </MenuBarMenu>
                                </div>
                            )}
                            <div
                                className={classNames(styles.menuBarItem, styles.hoverable, {
                                    [styles.active]: this.props.editMenuOpen
                                })}
                                onMouseUp={this.props.onClickEdit}
                            >
                                <Edit3Icon size={19} />
                                <span className={styles.collapsibleLabel}>
                                    <FormattedMessage
                                        defaultMessage="Edit"
                                        description="Text for edit dropdown menu"
                                        id="gui.menuBar.edit"
                                    />
                                </span>
                                <img src={dropdownCaret} />
                                <MenuBarMenu
                                    className={classNames(styles.menuBarMenu)}
                                    open={this.props.editMenuOpen}
                                    place={this.props.isRtl ? 'left' : 'right'}
                                    onRequestClose={this.props.onRequestCloseEdit}
                                >
                                    <MenuSection>
                                        <DeletionRestorer>{(handleRestore, { restorable, deletedItem }) => (
                                            <MenuItem
                                                // className={classNames({ [styles.disabled]: !restorable })}
                                                onClick={this.handleRestoreOption(handleRestore)}
                                            >
                                                {this.restoreOptionMessage(deletedItem)}
                                            </MenuItem>
                                        )}</DeletionRestorer>
                                        <TurboMode>{(toggleTurboMode, { turboMode }) => (
                                            <MenuItem onClick={toggleTurboMode}>
                                                {turboMode ? (
                                                    <FormattedMessage
                                                        defaultMessage="Turn off Turbo Mode"
                                                        description="Menu bar item for turning off turbo mode"
                                                        id="gui.menuBar.turboModeOff"
                                                    />
                                                ) : (
                                                    <FormattedMessage
                                                        defaultMessage="Turn on Turbo Mode"
                                                        description="Menu bar item for turning on turbo mode"
                                                        id="gui.menuBar.turboModeOn"
                                                    />
                                                )}
                                            </MenuItem>
                                        )}</TurboMode>
                                        {/* <MenuItem
                                            onClick={this.handleUndo}
                                        >
                                            <FormattedMessage
                                                defaultMessage="Undo"
                                                description="Menu bar item for Undo"
                                                id="gui.menubar.MYUndo"
                                            />
                                        </MenuItem>
                                    <MenuItem
                                            onClick={this.handleRedo}
                                        >
                                            <FormattedMessage
                                                defaultMessage="Redo"
                                                description="Menu bar item for Redo"
                                                id="gui.menubar.MYRedo"
                                            />
                                        </MenuItem> */}
                                    </MenuSection>
                                </MenuBarMenu>
                            </div>
                            <div className={styles.fileGroup}>
                                <div
                                    aria-label={this.props.intl.formatMessage(ariaMessages.tutorials)}
                                    className={classNames(styles.menuBarItem, styles.hoverable, styles.collapsibleLabel)}
                                    onClick={this.props.onOpenTipLibrary}
                                >
                                    <GraduationCapIcon size={20} className={styles.helpIcon} />
                                    <span className={styles.tutorialsLabel}>
                                        <FormattedMessage {...ariaMessages.tutorials} />
                                    </span>
                                </div>

                                <div
                                    aria-label={this.props.intl.formatMessage(ariaMessages.burnCode)}
                                    className={classNames(styles.menuBarItem, styles.hoverable, styles.collapsibleLabel)}
                                    onClick={this.handleBurnSelect}
                                >
                                    <ZapIcon size={20} className={"mr-1"} />
                                    <span className={styles.tutorialsLabel}>
                                        <FormattedMessage {...ariaMessages.burnCode} />
                                    </span>
                                </div>
                            </div>

                            <div>
                                <div className={styles.fileGroup}>
                                    <div className={classNames(styles.menuBarItem, styles.hoverable, styles.collapsibleLabel)} onClick={this.openBoardModal}>
                                        <CpuIcon size={20} className={"mr-1"} />

                                        <span className={styles.boardLabel} >
                                            <FormattedMessage
                                                defaultMessage=" Boards"
                                                description="Opens the menu to choose different boards"
                                                id="gui.menuBar.boards"
                                            />
                                        </span>
                                    </div>
                                </div>

                            </div>


                            {this.props.isTotallyNormal && (
                                <div
                                    className={classNames(styles.menuBarItem, styles.hoverable, {
                                        [styles.active]: this.props.modeMenuOpen
                                    })}
                                    onMouseUp={this.props.onClickMode}
                                >
                                    <div className={classNames(styles.editMenu)}>
                                        <FormattedMessage
                                            defaultMessage="Mode"
                                            description="Mode menu item in the menu bar"
                                            id="gui.menuBar.modeMenu"
                                        />
                                    </div>
                                    <MenuBarMenu
                                        className={classNames(styles.menuBarMenu)}
                                        open={this.props.modeMenuOpen}
                                        place={this.props.isRtl ? 'left' : 'right'}
                                        onRequestClose={this.props.onRequestCloseMode}
                                    >
                                        <MenuSection>
                                            <MenuItem onClick={this.handleSetMode('NOW')}>
                                                <span className={classNames({ [styles.inactive]: !this.props.modeNow })}>
                                                    {'✓'}
                                                </span>
                                                {' '}
                                                <FormattedMessage
                                                    defaultMessage="Normal mode"
                                                    description="April fools: resets editor to not have any pranks"
                                                    id="gui.menuBar.normalMode"
                                                />
                                            </MenuItem>
                                            <MenuItem onClick={this.handleSetMode('2020')}>
                                                <span className={classNames({ [styles.inactive]: !this.props.mode2020 })}>
                                                    {'✓'}
                                                </span>
                                                {' '}
                                                <FormattedMessage
                                                    defaultMessage="Caturday mode"
                                                    description="April fools: Cat blocks mode"
                                                    id="gui.menuBar.caturdayMode"
                                                />
                                            </MenuItem>
                                        </MenuSection>
                                    </MenuBarMenu>
                                </div>
                            )}
                        </div>
                        {/* <Divider className={classNames(styles.divider)} /> */}
                        <div>
                            <div className={styles.fileGroup}>
                                <div className={classNames(styles.menuBarItem, styles.hoverable, styles.collapsibleLabel)} onClick={this.openConnectModal}>
                                    <CableIcon size={20} className={"mr-1"} />
                                    <span className={styles.connectLabel} >
                                        <FormattedMessage
                                            defaultMessage="Connect"
                                            description="Opens the menu to connect"
                                            id="gui.menuBar.connect"
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className={styles.fileGroup}>
                                <div className={classNames(styles.menuBarItem, styles.hoverable, styles.collapsibleLabel)}
                                    onClick={this.openbuildRoboModal}>
                                    <Boxes size={20} className={"mr-1"} />
                                    <span className={styles.connectLabel} >
                                        <FormattedMessage
                                            defaultMessage="Build Robo"
                                            description="Opens the Build Robo"
                                            id="gui.menuBar.buildrobo"
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>


                    {this.state.hasUnsavedChanges && (
                        <button
                            className={classNames(
                                styles.menuBarItem,
                                styles.hoverable,
                                { [styles.saving]: this.state.isSaving }
                            )}
                            onClick={this.handleSaveProject}
                            disabled={this.state.isSaving}
                        >
                            {this.state.isSaving ? 'Saving...' : 'Save Now'}
                        </button>
                    )}

                    <div className={classNames(styles.menuBarItem, styles.growable)}>
                        <MenuBarItemTooltip
                            enable
                            id="title-field"
                        >
                            <ProjectTitleInput
                                className={classNames(styles.titleFieldGrowable)}
                            />
                        </MenuBarItemTooltip>
                    </div>
                    <div className={classNames(styles.menuBarItem)}>
                        {this.props.canShare ? (
                            (this.props.isShowingProject || this.props.isUpdating) && (
                                <ProjectWatcher onDoneUpdating={this.props.onSeeCommunity}>
                                    {
                                        waitForUpdate => (
                                            <ShareButton
                                                className={styles.menuBarButton}
                                                isShared={this.props.isShared}
                                                /* eslint-disable react/jsx-no-bind */
                                                onClick={() => {
                                                    this.handleClickShare(waitForUpdate);
                                                }}
                                            /* eslint-enable react/jsx-no-bind */
                                            />
                                        )
                                    }
                                </ProjectWatcher>
                            )
                        ) : (
                            this.props.showComingSoon ? (
                                <MenuBarItemTooltip id="share-button">
                                    <ShareButton className={styles.menuBarButton} />
                                </MenuBarItemTooltip>
                            ) : []
                        )}
                        {this.props.canRemix ? remixButton : []}
                    </div>
                    {/* <div className="mode-switcher">

                        <div className="mode-buttons" style={{ marginTop: "12px", marginLeft: "5px", background: "lightblue", padding: "2px" }}>
                            <span className="mode-label" style={{ marginRight: "10px" }}>Mode</span>
                            <button className="mode-button active">Stage</button>
                            <button className="mode-button">Upload</button>
                        </div>
                    </div> */}

                    {/* show the proper UI in the account menu, given whether the user is
                logged in, and whether a session is available to log in with */}
                    <div className={styles.accountInfoGroup}>
                        <div className={styles.menuBarItem}>
                            {this.props.canSave && (
                                <SaveStatus />
                            )}
                        </div>

                        {localStorage.getItem('accessToken') ? (
                            <React.Fragment>
                                {/* <a href="/mystuff/">
                                    <div
                                        className={classNames(
                                            styles.menuBarItem,
                                            styles.hoverable,
                                            styles.mystuffButton
                                        )}
                                    >
                                        <img
                                            className={styles.mystuffIcon}
                                            src={mystuffIcon}
                                        />
                                    </div>
                                </a> */}
                                <AccountNav
                                    className={classNames(
                                        styles.menuBarItem,
                                        styles.hoverable,
                                        { [styles.active]: this.props.accountMenuOpen }
                                    )}
                                    isOpen={this.props.accountMenuOpen}
                                    isRtl={this.props.isRtl}
                                    menuBarMenuClassName={classNames(styles.menuBarMenu)}
                                    onClick={this.props.onClickAccount}
                                    onClose={this.props.onRequestCloseAccount}
                                    onLogOut={this.props.onLogOut}
                                />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {/* <div
                                    className={classNames(
                                        styles.menuBarItem,
                                        styles.hoverable
                                    )}
                                    key="Join Stem"
                                    onMouseUp={this.handleJoinStemClick}
                                >
                                    <FormattedMessage
                                        defaultMessage="Register"
                                        description="Link for creating a Scratch account"
                                        id="gui.menuBar.Register"
                                    />
                                </div>
                                {this.state.showRegistrationForm && (
                                    <SignUpForm onClose={this.handleCloseForm} />
                                )}

                                <div
                                    style={{ textDecoration: 'none' }}
                                    className={classNames(styles.menuBarItem, styles.hoverable)}
                                    key="Stem World \n login"
                                    onMouseUp={this.props.onClickLogin}
                                >
                                    <FormattedMessage
                                        defaultMessage="Sign in 👤"
                                        description="Link for signing in to your Scratch account"
                                        id="gui.menuBar.signIn 👤"
                                    />
                                    <LoginDropdown
                                        isOpen={this.props.loginMenuOpen}
                                        isRtl={this.props.isRtl}
                                        onClose={this.props.onRequestCloseLogin}
                                        renderLogin={this.renderLogin}
                                    />
                                </div> */}
                            </React.Fragment>
                        )}
                    </div>
                    {this.props.aboutButton}
                </Box>
                {this.state.isModalOpen && <BoardSelectionModal onClose={this.closeBoardModal} />}
                {this.state.isConnectModalOpen && <ConnectModal onClose={this.closeConnectModal} />}
                {this.state.isbuildRoboModalOpen && (<UnityGame onClose={this.closebuildRoboModal} />)}
                {this.state.isfileopen && <Filesharing onClose={this.closefilemodal} />}
            </>
        );
    }
}

MenuBar.propTypes = {
    aboutMenuOpen: PropTypes.bool,
    accountMenuOpen: PropTypes.bool,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoUpdateProject: PropTypes.func,
    canChangeLanguage: PropTypes.bool,
    canChangeTheme: PropTypes.bool,
    audioVideoSetting: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    // canConnectTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    className: PropTypes.string,
    confirmReadyToReplaceProject: PropTypes.func,
    currentLocale: PropTypes.string.isRequired,
    editMenuOpen: PropTypes.bool,
    // connectMenuOpen: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    fileMenuOpen: PropTypes.bool,
    intl: intlShape,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    isTotallyNormal: PropTypes.bool,
    isUpdating: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    loginMenuOpen: PropTypes.bool,
    logo: PropTypes.string,
    mode1920: PropTypes.bool,
    mode1990: PropTypes.bool,
    mode2020: PropTypes.bool,
    mode220022BC: PropTypes.bool,
    modeMenuOpen: PropTypes.bool,
    modeNow: PropTypes.bool,
    onClickAbout: PropTypes.oneOfType([
        PropTypes.func, // button mode: call this callback when the About button is clicked
        PropTypes.arrayOf( // menu mode: list of items in the About menu
            PropTypes.shape({
                title: PropTypes.string, // text for the menu item
                onClick: PropTypes.func // call this callback when the menu item is clicked
            })
        )
    ]),
    onClickAccount: PropTypes.func,
    onClickEdit: PropTypes.func,
    // onClickConnect: PropTypes.func,
    onClickFile: PropTypes.func,
    onClickLogin: PropTypes.func,
    onClickLogo: PropTypes.func,
    onClickMode: PropTypes.func,
    onClickNew: PropTypes.func,
    onClickRemix: PropTypes.func,
    onClickSave: PropTypes.func,
    onClickSaveAsCopy: PropTypes.func,
    onClickfile: PropTypes.func,
    onClickSettings: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onOpenTipLibrary: PropTypes.func,
    onProjectTelemetryEvent: PropTypes.func,
    onRequestCloseAbout: PropTypes.func,
    onRequestCloseAccount: PropTypes.func,
    onRequestCloseEdit: PropTypes.func,
    // onRequestCloseConnect: PropTypes.func,
    onRequestCloseFile: PropTypes.func,
    onRequestCloseLogin: PropTypes.func,
    onRequestCloseMode: PropTypes.func,
    onRequestCloseSettings: PropTypes.func,
    onRequestOpenAbout: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onSetTimeTravelMode: PropTypes.func,
    onShare: PropTypes.func,
    onStartSelectingFileUpload: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    projectTitle: PropTypes.string,
    renderLogin: PropTypes.func,
    sessionExists: PropTypes.bool,
    settingsMenuOpen: PropTypes.bool,
    shouldSaveBeforeTransition: PropTypes.func,
    showComingSoon: PropTypes.bool,
    username: PropTypes.string,
    userOwnsProject: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired
};

MenuBar.defaultProps = {
    logo: scratchLogo,
    onShare: () => { }
};

const mapStateToProps = (state, ownProps) => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const user = state.session && state.session.session && state.session.session.user;
    return {
        aboutMenuOpen: aboutMenuOpen(state),
        accountMenuOpen: accountMenuOpen(state),
        currentLocale: state.locales.locale,
        fileMenuOpen: fileMenuOpen(state),
        editMenuOpen: editMenuOpen(state),
        // connectMenuOpen: connectMenuOpen(state),
        isRtl: state.locales.isRtl,
        isUpdating: getIsUpdating(loadingState),
        isShowingProject: getIsShowingProject(loadingState),
        locale: state.locales.locale,
        loginMenuOpen: loginMenuOpen(state),
        isRtl: state.locales.isRtl,
        modeMenuOpen: modeMenuOpen(state),
        projectTitle: state.scratchGui.projectTitle,
        sessionExists: state.session && typeof state.session.session !== 'undefined',
        settingsMenuOpen: settingsMenuOpen(state),
        username: user ? user.username : null,
        userOwnsProject: ownProps.authorUsername && user &&
            (ownProps.authorUsername === user.username),
        vm: state.scratchGui.vm,
        mode220022BC: isTimeTravel220022BC(state),
        mode1920: isTimeTravel1920(state),
        mode1990: isTimeTravel1990(state),
        mode2020: isTimeTravel2020(state),
        modeNow: isTimeTravelNow(state)
    };
};

const mapDispatchToProps = dispatch => ({
    autoUpdateProject: () => dispatch(autoUpdateProject()),
    onOpenTipLibrary: () => dispatch(openTipsLibrary()),
    onClickAccount: () => dispatch(openAccountMenu()),
    onRequestCloseAccount: () => dispatch(closeAccountMenu()),
    onClickFile: () => dispatch(openFileMenu()),
    onRequestCloseFile: () => dispatch(closeFileMenu()),
    onClickEdit: () => dispatch(openEditMenu()),
    onRequestCloseEdit: () => dispatch(closeEditMenu()),
    // onClickConnect: () => dispatch(openConnectMenu()),
    // onRequestCloseConnect: () => dispatch(closeConnectMenu()),
    onClickLogin: () => dispatch(openLoginMenu()),
    onRequestCloseLogin: () => dispatch(closeLoginMenu()),
    openLoginMenu: () => dispatch(openLoginMenu()),
    closeLoginMenu: () => dispatch(closeLoginMenu()),
    onClickMode: () => dispatch(openModeMenu()),
    onRequestCloseMode: () => dispatch(closeModeMenu()),
    onRequestOpenAbout: () => dispatch(openAboutMenu()),
    onRequestCloseAbout: () => dispatch(closeAboutMenu()),
    onClickSettings: () => dispatch(openSettingsMenu()),
    onRequestCloseSettings: () => dispatch(closeSettingsMenu()),
    onClickNew: needSave => dispatch(requestNewProject(needSave)),
    onClickRemix: () => dispatch(remixProject()),
    onClickSave: () => dispatch(manualUpdateProject()),
    onClickSaveAsCopy: () => dispatch(saveProjectAsCopy()),
    onclickfile: () => dispatch(Filesharing()),
    onSeeCommunity: () => dispatch(setPlayer(true)),
    onSetTimeTravelMode: mode => dispatch(setTimeTravel(mode))
});

export default compose(
    injectIntl,
    MenuBarHOC,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(MenuBar);