import classNames from 'classnames';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {defineMessages, intlShape, injectIntl} from 'react-intl';
import {setProjectTitle} from '../../reducers/project-title';
import ProjectService from '../../lib/project-service';

import BufferedInputHOC from '../forms/buffered-input-hoc.jsx';
import Input from '../forms/input.jsx';
const BufferedInput = BufferedInputHOC(Input);

import styles from './project-title-input.css';

const messages = defineMessages({
    projectTitlePlaceholder: {
        id: 'gui.gui.projectTitlePlaceholder',
        description: 'Placeholder for project title when blank',
        defaultMessage: 'Project title here'
    }
});

const ProjectTitleInput = ({
    className,
    intl,
    onSubmit,
    projectTitle
}) => {
    const [error, setError] = useState(null);
    const [isChecking, setIsChecking] = useState(false);

    const handleSubmit = async (newTitle) => {
        if (!newTitle) {
            setError('Title cannot be empty');
            return;
        }

        try {
            setIsChecking(true);
            setError(null);
            
            // Get current project ID from localStorage
            const currentProjectId = localStorage.getItem('currentProjectId');
            
            const isAvailable = await ProjectService.checkTitleAvailability(
                newTitle, 
                currentProjectId
            );

            if (!isAvailable && newTitle !== projectTitle) {
                setError('A project with this name already exists');
                return;
            }

            // If validation passes, call the original onSubmit
            onSubmit(newTitle);
            
        } catch (err) {
            setError('Failed to validate title');
            console.error('Title validation error:', err);
        } finally {
            setIsChecking(false);
        }
    };

    return (
        <div className={classNames(styles.titleFieldWrapper)}>
            <BufferedInput
                className={classNames(styles.titleField, className, {
                    [styles.titleFieldError]: error
                })}
                maxLength="100"
                placeholder={intl.formatMessage(messages.projectTitlePlaceholder)}
                tabIndex="0"
                type="text"
                value={projectTitle}
                onSubmit={handleSubmit}
            />
            {error && (
                <div className={styles.errorMessage}>
                    {error}
                </div>
            )}
            {isChecking && (
                <div className={styles.validating}>
                    Checking title...
                </div>
            )}
        </div>
    );
};

ProjectTitleInput.propTypes = {
    className: PropTypes.string,
    intl: intlShape.isRequired,
    onSubmit: PropTypes.func,
    projectTitle: PropTypes.string
};

const mapStateToProps = state => ({
    projectTitle: state.scratchGui.projectTitle
});

const mapDispatchToProps = dispatch => ({
    onSubmit: title => dispatch(setProjectTitle(title))
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectTitleInput));
