import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../ui/card';
import { EyeIcon, EyeOffIcon, Loader2 } from 'lucide-react';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import apiClient from '../../utils/apiClient';
// toast.configure();

const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    const requirements = [
        { met: password.length >= minLength, text: 'At least 8 characters' },
        { met: hasUpperCase, text: 'One uppercase letter' },
        { met: hasLowerCase, text: 'One lowercase letter' },
        { met: hasDigit, text: 'One number' },
        { met: hasSpecialChar, text: 'One special character' }
    ];

    return {
        isValid: requirements.every(req => req.met),
        requirements
    };
};

const ResetPasswordForm = ({ token, onSuccess }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const passwordValidation = validatePassword(newPassword);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!passwordValidation.isValid) {
            setError('Please meet all password requirements');
            return;
        }

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        setIsLoading(true);
        try {
            const response = await apiClient.post(`/auth/reset-password/${token}`, {
                password: newPassword
            });

            if (response.status === 200) {
                // toast.success('Password reset successfully! Please login with your new password.', {
                // });
                onSuccess();
            }
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to reset password');
            // toast.error(err.response?.data?.error || 'Failed to reset password', {
            // });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Card className="w-full max-w-md bg-white/90 backdrop-blur-md shadow-xl border-0">
            <CardHeader>
                <CardTitle className="text-2xl font-bold text-center">Reset Password</CardTitle>
                <CardDescription className="text-center text-gray-600">
                    Enter your new password below
                </CardDescription>
            </CardHeader>
            <CardContent>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="new-password" className="text-gray-700">New Password</Label>
                        <div className="relative">
                            <Input
                                id="new-password"
                                type={showPassword ? "text" : "password"}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="Enter new password"
                                required
                                className="bg-white/50 border-cyan-200 focus:border-cyan-400 focus:ring-cyan-400 pr-10"
                            />
                            <Button
                                type="button"
                                variant="ghost"
                                size="icon"
                                className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? (
                                    <EyeOffIcon className="h-4 w-4 text-gray-500" />
                                ) : (
                                    <EyeIcon className="h-4 w-4 text-gray-500" />
                                )}
                            </Button>
                        </div>
                        
                        {/* Password requirements */}
                        <div className="space-y-1 mt-2">
                            {passwordValidation.requirements.map((req, index) => (
                                <div key={index} className="flex items-center space-x-2 text-sm">
                                    <div className={`w-2 h-2 rounded-full ${req.met ? 'bg-green-500' : 'bg-gray-300'}`} />
                                    <span className={req.met ? 'text-green-600' : 'text-gray-500'}>
                                        {req.text}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="confirm-password" className="text-gray-700">Confirm Password</Label>
                        <div className="relative">
                            <Input
                                id="confirm-password"
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm new password"
                                required
                                className="bg-white/50 border-cyan-200 focus:border-cyan-400 focus:ring-cyan-400 pr-10"
                            />
                            <Button
                                type="button"
                                variant="ghost"
                                size="icon"
                                className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                {showConfirmPassword ? (
                                    <EyeOffIcon className="h-4 w-4 text-gray-500" />
                                ) : (
                                    <EyeIcon className="h-4 w-4 text-gray-500" />
                                )}
                            </Button>
                        </div>
                    </div>

                    {error && (
                        <p className="text-sm text-red-500">{error}</p>
                    )}

                    <Button
                        type="submit"
                        disabled={isLoading}
                        className="w-full bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white"
                    >
                        {isLoading ? (
                            <div className="flex items-center justify-center">
                                <Loader2 className="h-4 w-4 animate-spin mr-2" />
                                <span>Resetting Password...</span>
                            </div>
                        ) : (
                            'Reset Password'
                        )}
                    </Button>
                </form>
            </CardContent>
        </Card>
    );
};

ResetPasswordForm.propTypes = {
    token: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired
};

export default ResetPasswordForm; 