import apiClient from '../utils/apiClient';

class ProjectService {
    static async saveProject(projectData, title, isNew = false) {
        const formData = new FormData();
        const sb3Blob = new Blob([projectData], { type: 'application/x.scratch.sb3' });
        formData.append('sb3File', sb3Blob, `${title}.sb3`);
        formData.append('title', title);
        formData.append('user_id', localStorage.getItem('userId'))
        
        const token = localStorage.getItem('accessToken');
        const endpoint = isNew ? '/projects/create' : `/projects/${localStorage.getItem('currentProjectId')}/update`;
        const method = isNew ? 'post' : 'put';

        try {
            const response = await apiClient[method](endpoint, formData);

            if (isNew) {
                localStorage.setItem('currentProjectId', response.data.id);
            }

            return response.data;
        } catch (error) {
            console.error('Project save error:', error);
            throw error;
        }
    }

    static async loadProject(projectId) {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await apiClient.get(`/projects/${projectId}/load`, {
                headers: { 
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/x.scratch.sb3'
                },
                responseType: 'arraybuffer'
            });
            return response.data;
        } catch (error) {
            console.error('Project load error:', error);
            throw error;
        }
    }

    static async checkTitleAvailability(title, currentProjectId = null) {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await apiClient.get('/projects/check-title', {
                headers: { Authorization: `Bearer ${token}` },
                params: { 
                    title,
                    currentProjectId // Include current project ID to exclude it from duplicate check
                }
            });
            return response.data.available;
        } catch (error) {
            console.error('Title check error:', error);
            throw error;
        }
    }

    static async createNewProject() {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await apiClient.post('/projects/new', null, {
                headers: { Authorization: `Bearer ${token}` }
            });
            localStorage.setItem('currentProjectId', response.data.id);
            return response.data;
        } catch (error) {
            console.error('New project creation error:', error);
            throw error;
        }
    }

    static async getThumbnail(projectId) {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await apiClient.get(`/projects/${projectId}/thumbnail`, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'blob'
            });
            return URL.createObjectURL(response.data);
        } catch (error) {
            console.error('Thumbnail fetch error:', error);
            throw error;
        }
    }
}

export default ProjectService; 