import axios from 'axios';
import { refreshAccessToken, logoutUser } from './authService';

// Function to check if token is expired
const isTokenExpired = (token) => {
  if (!token) return true;
  
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    // Add 10 second buffer to prevent edge cases
    return (payload.exp * 1000) < (Date.now() + 10000);
  } catch (error) {
    console.error('Error parsing token:', error);
    return true;
  }
};

// Setup periodic token check
const setupTokenExpirationCheck = () => {
  const checkInterval = setInterval(async () => {
    const token = localStorage.getItem('accessToken');
    if (token && isTokenExpired(token)) {
      console.log('Token expired during periodic check');
      clearInterval(checkInterval);
      try {
        console.log("Caling from setupTokenexpiration check............")
        const newToken = await refreshAccessToken();
        console.log(newToken)
        if (newToken) {
          localStorage.setItem('accessToken', newToken);
          // console.log('Access token stored:', localStorage.getItem('accessToken'));
        } else {
          logoutUser();
          throw new Error('Session expired');
        }
      } catch (error) {
        logoutUser();
        throw new Error('Authentication failed');
      }
      // logoutUser();
    }
  }, 60000); // Check every minute

  // Clean up interval on window unload
  window.addEventListener('unload', () => clearInterval(checkInterval));
};

// Initialize token check when client is created
setupTokenExpirationCheck();

const apiClient = axios.create({
  baseURL: 'https://api.stemverse.app', //https://api.stemverse.app
  withCredentials: true,
});

// Request Interceptor with token expiration check
apiClient.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    
    // Check token expiration before making request
    if (accessToken) {
      if (isTokenExpired(accessToken)) {
        console.log('Token expired, attempting refresh before request...');
        try {
          const newToken = await refreshAccessToken();
          if (newToken) {
            localStorage.setItem('accessToken', newToken);
            config.headers.Authorization = `Bearer ${newToken}`;
          } else {
            logoutUser();
            throw new Error('Session expired');
          }
        } catch (error) {
          logoutUser();
          throw new Error('Authentication failed');
        }
      } else {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor with enhanced error handling
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Handle network errors
    if (!error.response) {
      console.error('Network error:', error);
      return Promise.reject(new Error('Network error occurred'));
    }

    // Handle 401 Unauthorized errors
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshAccessToken();
        if (newAccessToken) {
          console.log("Refreshing the token........")
          localStorage.setItem('accessToken', newAccessToken);
          apiClient.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
          return apiClient(originalRequest);
        } else {
          console.log('Token refresh failed, logging out...');
          logoutUser();
          return Promise.reject(new Error('Session expired'));
        }
      } catch (err) {
        console.error('Token refresh error:', err);
        logoutUser();
        return Promise.reject(new Error('Authentication failed'));
      }
    }

    // Handle 403 Forbidden errors
    if (error.response.status === 403) {
      console.error('Access forbidden:', error.response.data);
      return Promise.reject(new Error('Access denied'));
    }

    // Handle other errors
    return Promise.reject(error);
  }
);

export default apiClient;