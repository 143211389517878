import VM from 'scratch-vm';
import EventEmitter from 'events';

export function createVMBridge() {
    let ws = null;
    let vmInstance = null;
    let sprites = new Map();

    const bridge = {
        initialize: function(vm) {
            vmInstance = vm;
            this.setupWebSocket();
        },

        setupWebSocket: function() {
            if (ws) ws.close();
            
            ws = new WebSocket('https://api.stemverse.app');
            
            ws.onmessage = (event) => {
                try {
                    const message = JSON.parse(event.data);
                    console.log("Received message:", message);
                    
                    switch (message.type) {
                        case 'create_sprite':
                        case 'scratch/create_sprite':
                            this.handleCreateSprite(message.data);
                            break;
                        case 'move_sprite':
                        case 'scratch/move_sprite':
                            this.handleMoveSprite(message.data);
                            break;
                        case 'rotate_sprite':
                        case 'scratch/rotate_sprite':
                            this.handleRotateSprite(message.data);
                            break;
                    }
                } catch (error) {
                    console.error('Message handling error:', error);
                }
            };
        },

        handleCreateSprite: function(data) {
            if (!vmInstance || !vmInstance.runtime) return;

            try {
                // Create new sprite from default
                const newSprite = {
                    name: data.name,
                    isStage: false,
                    x: data.x,
                    y: data.y,
                    visible: true,
                    size: 100,
                    direction: 90,
                    draggable: true,
                    currentCostume: 0,
                    costume: {
                        name: 'costume1',
                        bitmapResolution: 1,
                        rotationCenterX: 0,
                        rotationCenterY: 0
                    }
                };

                const target = vmInstance.runtime.makeSprite(newSprite, vmInstance.runtime.targets[0]);
                sprites.set(data.id, target);

                // Position sprite
                target.setXY(data.x, data.y);
                vmInstance.runtime.requestTargetsUpdate(target);
                vmInstance.runtime.requestRedraw();

                console.log('Created sprite:', target);
            } catch (error) {
                console.error('Sprite creation error:', error);
            }
        },

        handleMoveSprite: function(data) {
            if (!vmInstance || !vmInstance.runtime) return;

            try {
                const target = sprites.get(data.id);
                if (target) {
                    target.setXY(data.x, data.y);
                    vmInstance.runtime.requestTargetsUpdate(target);
                    vmInstance.runtime.requestRedraw();
                    console.log('Moved sprite:', data);
                }
            } catch (error) {
                console.error('Sprite movement error:', error);
            }
        },

        handleRotateSprite: function(data) {
            if (!vmInstance || !vmInstance.runtime) return;

            try {
                const target = sprites.get(data.id);
                if (target) {
                    target.setDirection(data.angle);
                    vmInstance.runtime.requestTargetsUpdate(target);
                    vmInstance.runtime.requestRedraw();
                    console.log('Rotated sprite:', data);
                }
            } catch (error) {
                console.error('Sprite rotation error:', error);
            }
        },

        cleanup: function() {
            if (ws) {
                ws.close();
                ws = null;
            }
            sprites.clear();
            vmInstance = null;
        }
    };

    return bridge;
}